import { Box, Container, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GUEST_LEVEL, ROUTE_BASE } from "./common/env";
import { Devices } from "./components/Devices";
import { Error } from "./components/Error";
import Dashboard from "./components/layouts/Dashboard";
import { Loading } from "./components/Loading";
import { Login } from "./components/Login";
import { useUserInfo } from "./hooks/useUserInfo";

function App() {
  const { user, loading: loadingUser, error } = useUserInfo();

  const { t } = useTranslation("root");

  if (error)
    return (
      <Container>
        <Error message={error?.message} />
      </Container>
    );

  if (loadingUser) return <Loading />;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/`}
          element={
            <Box>
              <Dashboard>
                {Number(user.level) <= GUEST_LEVEL && <Login />}
                {Number(user.level) > GUEST_LEVEL && (
                  <Devices key={"devices-list"} />
                )}
              </Dashboard>
              {/* <BottomMenu /> */}
            </Box>
          }
        />
        <Route
          path={`${ROUTE_BASE}/`}
          element={
            <Box>
              <Dashboard>
                {Number(user.level) <= GUEST_LEVEL && <Login />}
                {Number(user.level) > GUEST_LEVEL && (
                  <Devices key={"devices-list-guest"} />
                )}
              </Dashboard>
              {/* <BottomMenu /> */}
            </Box>
          }
        />
        <Route
          path={`${ROUTE_BASE}/login`}
          element={
            <Box>
              <Dashboard>
                <Login />
              </Dashboard>
            </Box>
          }
        />
      </Routes>

      {Number(user.level) > GUEST_LEVEL && (
        <Routes>
          <Route
            path={`${ROUTE_BASE}/admin/`}
            element={
              <Box>
                <Dashboard fullWidth>admin panel</Dashboard>
              </Box>
            }
          />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
