import { Paper, Snackbar, Typography } from '@mui/material'
import anime from 'animejs'
import { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { snackBarSelector } from '../../state-manager/GlobalProvider'
import useStyles from '../../styles'
import { theme } from '../../theme'

export const GlobalSnackBar = () => {
  const snackbar = useRecoilValue(snackBarSelector)
  const { shadow, p10, backdropFilter } = useStyles()
  const loadingRef: any = useRef<HTMLDivElement>()
  const duration: number = (snackbar.timeout || 0) * 0.99

  useEffect(() => {
    if (!snackbar.timeout) return
    anime({
      targets: loadingRef.current,
      width: 0,
      duration,
      autoplay: true,
      easing: 'linear'
    })
  }, [snackbar.timeout])

  return (
    <Snackbar {...snackbar}>
      <Paper
        className={`${shadow} ${p10} ${backdropFilter}`}
        style={{
          background: '#000',
          opacity: 0.8,
          minWidth: '300px',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Typography color="#fff">{snackbar.message}</Typography>
        {duration > 0 && (
          <>
            <div
              style={{
                backgroundColor: theme.palette.secondary.dark,
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '7px',
                zIndex: 1
              }}
            ></div>
            <div
              ref={loadingRef}
              style={{
                backgroundColor: theme.palette.secondary.light,
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '7px',
                zIndex: 5
              }}
            ></div>
          </>
        )}
      </Paper>
    </Snackbar>
  )
}
