import { Grid, Link, Typography } from "@mui/material";
import { MAIN_WEBSITE } from "../../common/env";
import { useStyles } from "./../../styles";

export const Footer = () => {
  const classes = useStyles();
  const date = new Date();
  return (
    <Grid container p={2}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="subtitle1">
          <Link className={classes.link} href={MAIN_WEBSITE}>
            PRO.COM S.r.l. 2004 - {date.getFullYear()}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
