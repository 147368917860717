import { atom } from 'recoil'

export interface GlobalModalStoreInterface {
  visible: boolean
  children: any
}

export const globalModalStore = atom<GlobalModalStoreInterface>({
  key: 'globalModal',
  default: { visible: false, children: null }
})
