import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: `#e5e8e3`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          position: "relative",
          display: "flex",
          margin: 0,
          padding: 0,
          height: "100%",
          alignItems: "center",
          justifyContent: "space-around",
          boxShadow: `0px 0px 5px #ccc`,
          border: `1px solid #c4c4c4`,
          overflow: "hidden",
          borderRadius: "5px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          textTransform: "uppercase",
        },
        h5: {
          fontSize: "1.2rem",
        },
        subtitle2: {
          fontSize: "0.7rem",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          margin: "auto",
          padding: 0,
        },
      },
    },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  palette: {
    primary: {
      light: "#67daff",
      main: "#03a9f4",
      dark: "#007ac1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff833a",
      main: "#e65100",
      dark: "#ac1900",
      contrastText: "#000",
    },
    background: {
      paper: "#ffffff",
      default: "#d8f6ff",
    },
  },
  zIndex: {
    tooltip: 100,
  },
});
