import { GUEST_LEVEL, USER_LEVEL, CUSTOMER_CARE_LEVEL, ADMIN_USER_LEVEL } from '../common/env'
import { UserRoleEnum } from './../interface/UserRoleEnum'
export const userRoleToLevel = (userRole: string) => {
  let level

  switch (userRole) {
    default:
      level = GUEST_LEVEL
      break
    case UserRoleEnum.USER:
      level = USER_LEVEL
      break
    case UserRoleEnum.CARE:
      level = CUSTOMER_CARE_LEVEL
      break
    case UserRoleEnum.ADMIN:
      level = ADMIN_USER_LEVEL
      break
  }

  return level
}
