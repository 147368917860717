import retry from "p-retry";
import { ApiDeviceAdapter } from "../interface/ApiDeviceAdapter";
import { delay } from "./../util/delay";
import {
  checkWebTunneilIsOpen,
  invokeTunnel,
  revokeTunnel,
} from "./invokeTunnel";

export const checkWebTunnel = (port: number) => {
  return checkWebTunneilIsOpen(port);
};

export const closeWebTunnel = async ({
  id,
  proxyPort,
}: {
  id?: number;
  proxyPort: number;
}) => {
  return new Promise<boolean>((resolve, reject) => {
    retry(
      async () => {
        await revokeTunnel({
          deviceId: id,
          currentPort: proxyPort,
          type: "web",
        });
        const isOpen = await checkWebTunnel(proxyPort);
        if (isOpen) return Promise.reject("tunnel not closed");
        return Promise.resolve();
      },
      {
        retries: 3,
        onFailedAttempt: async (error) => {
          console.log("closeWebTunnel", error);
          await delay(1000);
        },
      }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const invokeWebProxy = ({ id, proxyPort }: ApiDeviceAdapter) => {
  return new Promise<boolean>((resolve, reject) => {
    retry(
      async () => {
        await invokeTunnel({ deviceId: id, type: "web" });
        await delay(1000);
        const isOpen = await checkWebTunnel(proxyPort);
        if (!isOpen) throw "tunnel not open";
      },
      { retries: 3 }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
