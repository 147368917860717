import { MAIN_API_URL } from "../common/env";
import { AxiosClient } from "./axiosClient";

export const requestDeviceUpgrade = async (uuid: string) => {
  const axios = AxiosClient();
  const response = await axios.get(`/${MAIN_API_URL}/device/upgrade/${uuid}`);
  if (response.status !== 200) {
    throw new Error("Upgrade request failed");
  }
};
