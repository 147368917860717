import cookie from "js-cookie";
//import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { USER_TOKEN_COOKIENAME } from "../common/env";
//import { JWTUserToken } from "../interface/JWTUserToken";
import { LoginCredentials } from "../interface/LoginCredentials";
import { loginWithApi } from "../service/loginWithApi";
import { getDomain } from "../util/getDomain";

export const useLogin = (params?: {
  onLogin?: () => void;
  onLogout?: () => void;
}) => {
  const [response, tryLogin] = useState<LoginCredentials>();
  const [error, setError] = useState<Error>();

  const logout = () => {
    cookie.remove(USER_TOKEN_COOKIENAME, { domain: getDomain() });
    if (typeof params?.onLogout === "function") params.onLogout();
  };

  const login = (data: LoginCredentials) => tryLogin(data);

  useEffect(() => {
    if (!response) return;

    loginWithApi(response)
      .then(({ token }) => {
        const domain = getDomain();
        //const { exp } = jwt<JWTUserToken.Content>(token);
        cookie.set(USER_TOKEN_COOKIENAME, token, {
          domain,
          expires: 1,
          secure: true,
        });
        if (typeof params?.onLogin === "function") params.onLogin();
      })
      .catch(setError)
      .finally(() => {
        tryLogin(undefined);
      });
  }, [response]);

  return { login, error, logout, response };
};
