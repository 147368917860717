import { atom, selector } from 'recoil'
import { JWTUserToken } from '../interface/JWTUserToken'
import { getUserData } from '../service/getUserData'

export interface UserInfoStoreInterface {
  data: JWTUserToken.Adapter | null
  level: number | null
}

export const userInfoStoreDefault = { data: null, level: null }

export const userInfoStore = atom<UserInfoStoreInterface>({
  key: 'UserInfoStore',
  default: userInfoStoreDefault
})
