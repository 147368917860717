import { Circle, Close, OpenInNew, Web } from "@mui/icons-material";
import { Box, Button, Paper, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import cookie from "js-cookie";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { ApiDeviceAdapter } from "../../interface/ApiDeviceAdapter";
import { closeWebTunnel, invokeWebProxy } from "../../service/invokeProxies";
import { headerSpinner } from "../../state-manager/HeaderSpinner";
import useStyles from "../../styles";
import { theme } from "../../theme";
import { getDomain } from "../../util/getDomain";

const openDashboard = (port: number) => {
  window.location.href = `/dashboard-${port}`;
};

function createProxyCookie(proxyPort: number) {
  const domain = getDomain();
  cookie.set("proxyPort", `${proxyPort}`, { expires: 1, domain });
}

export const Device: React.FC<{
  device: ApiDeviceAdapter;
  parentLoading: boolean;
  refreshDevice: Function;
}> = ({ device, refreshDevice, parentLoading }) => {
  const { t } = useTranslation("devices");
  const { pointer, shimmer } = useStyles();

  const showSpinner = useSetRecoilState(headerSpinner);
  const [loading, setLoading] = useState<boolean>(parentLoading);

  const showLoading = () => setLoading(true);

  const hideLoading = () => {
    if (!parentLoading) setLoading(false);
  };

  useEffect(() => {
    showSpinner(loading);
  }, [loading]);

  const openWebTunnel = async (device: ApiDeviceAdapter) => {
    showLoading();
    try {
      createProxyCookie(device.proxyPort);

      const opened = await invokeWebProxy(device);
      if (opened) await openDashboard(device.proxyPort);
    } catch (error) {}

    refreshDevice();
    hideLoading();
  };

  const revokeWebPort = async (device: ApiDeviceAdapter) => {
    showLoading();
    try {
      await closeWebTunnel(device);
    } catch (error) {}
    refreshDevice();
    hideLoading();
  };

  useEffect(() => {
    const qsParams = parse(window.location.search);
    if (
      !(
        qsParams.closeWebTunnel &&
        qsParams.closeWebTunnel.length > 0 &&
        device.proxyPort == Number(qsParams.closeWebTunnel)
      )
    )
      return;

    setLoading(true);

    closeWebTunnel({
      proxyPort: Number(qsParams.closeWebTunnel),
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        window.history.pushState({}, document.title, "/");
        setTimeout(() => {
          refreshDevice();
          setLoading(false);
        }, 5000);
      });
  });

  let pingStatusText = t("Disconnesso");
  let pingStatusColor: "disabled" | "primary" | "secondary" = "disabled";
  if (typeof device?.lastPing?.date === "string") {
    const lastPingMinute = Math.abs(
      dayjs(device?.lastPing?.date).diff(Date.now(), "minute")
    );

    pingStatusText = t(`Ultimo ping ${lastPingMinute} minuti fa`);

    if (lastPingMinute <= 1) {
      pingStatusColor = "primary";
    } else {
      pingStatusColor = "secondary";
    }

    if (lastPingMinute >= 5) {
      pingStatusColor = "disabled";
      pingStatusText = t("Disconnesso");
    }
  }

  return (
    <Paper
      style={{
        border: device.development
          ? `1px solid ${theme.palette.primary.dark}`
          : "",
      }}
    >
      <Box p={2} style={{ minHeight: "110px" }}>
        <Box p={2}>
          <b>{device.label}</b>
        </Box>
        <Box
          className={`${loading ? shimmer : null}`}
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          {!device.portConnected && (
            <Button
              className={pointer}
              variant="outlined"
              onClick={async () => await openWebTunnel(device)}
            >
              <Web style={{ marginRight: "0.4rem" }} fontSize="large" />
              {t("Collegati")}
            </Button>
          )}
          {device.portConnected && (
            <>
              <Button
                className={pointer}
                variant="contained"
                onClick={async () => {
                  createProxyCookie(device.proxyPort);
                  await openDashboard(device.proxyPort);
                }}
              >
                <OpenInNew fontSize="large" className={pointer} />
                {t("Dashboard")}
              </Button>
              <Button
                className={pointer}
                style={{ marginLeft: "0.4rem" }}
                color="secondary"
                variant="outlined"
                onClick={async () => await revokeWebPort(device)}
              >
                <Close fontSize="large" className={pointer} />
              </Button>
            </>
          )}
          <Tooltip title={pingStatusText}>
            <Circle
              color={pingStatusColor}
              style={{ margin: "1rem", cursor: "help" }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
};
