import { MAIN_API_URL } from "./../common/env";
import { AxiosClient } from "./axiosClient";
export declare module InvokeTunnel {
  export interface Params {
    deviceId?: number;
    currentPort?: number;
    type: "ssh" | "web";
  }
}

const requestTunnel = async (
  options: InvokeTunnel.Params,
  action: "open" | "close" = "open"
) => {
  const axios = AxiosClient();
  const { data } = await axios.post<{ uuid: string; port: number }>(
    `/${MAIN_API_URL}/device/tunnel/${action}`,
    options
  );
  return data;
};

export const checkWebTunneilIsOpen = async (port: number) => {
  const axios = AxiosClient();

  let status = false;
  for (let i = 0; i <= 2; i++) {
    if (status) return;
    const response = await axios.get(`/dashboard-${port}`, {
      timeout: 1000,
      maxRedirects: 0,
    });

    status = response.status <= 301;
    if (status === true) break;
  }

  return status;
};

export const invokeTunnel = async ({ deviceId, type }: InvokeTunnel.Params) => {
  return await requestTunnel({ deviceId, type }, "open");
};

export const revokeTunnel = async ({
  deviceId,
  currentPort,
  type,
}: InvokeTunnel.Params) => {
  return await requestTunnel({ deviceId, currentPort, type }, "close");
};
