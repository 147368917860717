import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const timelineItem = {
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.7rem",
  display: "flex",
  height: "100%",
  overflow: "hidden",
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  headerInfo: {
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    marginBottom: theme.spacing(1),
    borderRadius: "5px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    width: "100%",
    maxWidth: "100px",
    padding: theme.spacing(1),
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(0),
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  separator: {
    margin: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  spinner: {
    display: "flex",
    justifyContent: "space-around",
    justifyItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100vh",
    width: "10vwh",
    backgroundColor: theme.palette.background.default,
    zIndex: 10000,
    margin: "auto",
  },
  hide: {
    display: "none",
  },
  tr: {
    textAlign: "right",
  },
  tabsContainer: {
    overflow: "hidden",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    border: `1px solid ${theme.palette.divider}`,
  },
  tabItem: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.default,
    },
  },
  "[disabled]": {
    opacity: 0.5,
  },
  ".Mui-disabled": {
    opacity: 0.5,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  linkFill: {
    width: "100%",
    height: "100%",
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  registerBox: {
    width: "100%",
    overflow: "hidden",
    maxHeight: "300px",
    minHeight: "120px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
  },
  shadow: {
    boxShadow: `0px 0px 5px #ccc`,
  },
  radius: {
    borderRadius: "5px",
  },
  m0: {
    margin: 0,
  },
  p0: {
    padding: 0,
  },
  p10: {
    padding: "10px",
  },
  flex: {
    display: "flex",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  center: { textAlign: "center" },
  bgDefault: {
    background: theme.palette.background.default,
  },
  bgPaper: {
    background: theme.palette.background.paper,
  },
  timeline: {
    position: "relative",
    height: "3rem",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    borderRadius: "5px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  timelineOn: {
    cursor: "pointer",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
    ...timelineItem,
  },
  timelineOff: {
    ...timelineItem,
  },
  timelineRowEven: {
    background: "#d9eff7",
  },
  timelineRowOdd: {
    background: "#c7dae0",
  },
  timelineLabel: {
    position: "absolute",
    bottom: 0,
    right: 0,
    background: theme.palette.primary.dark,
    color: theme.palette.background.paper,
    borderRadius: "10px 0px 0px 0px",
    padding: "5px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "40vw",
    minHeight: "40vh",
    background: theme.palette.background.paper,
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(4),
    borderRadius: "5px",
  },
  backdropFilter: {
    backdropFilter: "blur(20px)",
  },
  pointer: {
    cursor: "pointer",
  },
  shimmer: {
    pointerEvents: "none",
    filter: "saturate(0)",
    "-webkit-mask": `linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%`,
    animation: "shimmer 2.5s infinite",
  },
  disabled: {
    pointerEvents: "none",
    filter: "saturate(0)",
  },
}));

export default useStyles;
