import { Link } from "@mui/material";
import { MAIN_WEBSITE } from "../../common/env";
import { useStyles } from "./../../styles";
import logo from "./../../assets/logo.webp";

export const Logo: React.FC<{ clickable?: boolean }> = ({
  clickable = true,
}) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.link}
      style={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        pointerEvents: clickable ? "initial" : "none",
      }}
      href={MAIN_WEBSITE}
    >
      <img
        height="100%"
        style={{ margin: "auto", maxHeight: "2rem" }}
        src={logo}
      />
    </Link>
  );
};
