import { Close, Info, Terminal, Upgrade } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ADMIN_USER_LEVEL, DEVICE_REFRESH_INTERVAL } from "../common/env";
import { useCurrentDevices } from "../hooks/useCurrentDevices";
import { ApiDeviceAdapter } from "../interface/ApiDeviceAdapter";
import { invokeTunnel, revokeTunnel } from "../service/invokeTunnel";
import { globalModalStore } from "../state-manager/GlobalModalStore";
import { headerSpinner } from "../state-manager/HeaderSpinner";
import { userInfoStore } from "../state-manager/UserInfoStore";
import useStyles from "../styles";
import { Device } from "./devices-list/Device";
import { requestDeviceUpgrade } from "../service/requestDeviceUpgrade";

export const getDevice = (devicesStatus: ApiDeviceAdapter[], uuid: string) => {
  return devicesStatus
    .filter((device) => device.uuid === uuid)
    .reduce((_, value) => value);
};

const invokeSSHProxy = async (device: ApiDeviceAdapter) => {
  try {
    return await invokeTunnel({ deviceId: device.id, type: "ssh" });
  } catch (error) {
    console.error("Failed to open ssh tunnel", error);
  }
};

const revokeSSHTunnel = async ({
  id,
  port,
}: {
  id?: number;
  port?: number;
}) => {
  try {
    return await revokeTunnel({ deviceId: id, currentPort: port, type: "ssh" });
  } catch (error) {}
};

const requestUpgrade = (device: ApiDeviceAdapter) => {
  return requestDeviceUpgrade(device.uuid);
};

export const Devices: React.FC = () => {
  const { t } = useTranslation("devices");

  const { disabled, flexWrap } = useStyles();
  const { loading, devices, get } = useCurrentDevices();

  const [sshTunnel, setSshTunnel] = useState<{ uuid: string; sshPort: number }>(
    {
      uuid: "",
      sshPort: 0,
    }
  );

  const showSpinner = useSetRecoilState(headerSpinner);
  const user = useRecoilValue(userInfoStore);
  const setModal = useSetRecoilState(globalModalStore);

  useEffect(() => {
    showSpinner(loading);
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => get(), DEVICE_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const closeSSHTunnel = async (device: { id?: number }) => {
    showSpinner(true);
    const config = await revokeSSHTunnel(device);
    if (typeof config !== "undefined") {
      setSshTunnel({ sshPort: 0, uuid: "" });
    }

    showSpinner(false);
  };

  const openSSHTunnel = async (device: ApiDeviceAdapter) => {
    const config = await invokeSSHProxy(device);
    if (typeof config !== "undefined") {
      setSshTunnel({ sshPort: config.port, uuid: config.uuid });
    }
  };

  const closeModal = () => setModal({ visible: false, children: <></> });

  const showDetails = ({ uuid, info, updateAt }: ApiDeviceAdapter) => {
    setModal({
      visible: true,
      children: (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <b>UUID: {uuid}</b>
          {dayjs(updateAt).format("HH:mm DD/MM/YYYY")}
          <br />
          <Box
            style={{
              overflow: "auto",
              maxHeight: window.innerHeight * 0.75,
              minWidth: window.innerWidth * 0.6,
              maxWidth: window.innerWidth * 0.9,
            }}
          >
            <pre>{JSON.stringify(info, null, 2)}</pre>
          </Box>
          <Box display="flex" justifyContent="space-evenly">
            <Button variant="outlined" onClick={() => closeModal()}>
              {t("Chiudi")}
            </Button>
          </Box>
        </Box>
      ),
    });
  };

  const isAdmin = !!(user.level && user.level >= ADMIN_USER_LEVEL);

  return (
    <Grid container xs={12} spacing={2}>
      {user.level && user.level >= ADMIN_USER_LEVEL && (
        <Grid item xs={isAdmin ? 3 : 0} key={`key-device-list-title-admin`}>
          <Typography>{t("Admin")}</Typography>
        </Grid>
      )}
      <Grid item xs={isAdmin ? 9 : 12} key={`key-device-list-title-user`}>
        <Typography>{t("I tuoi dispositivi")}</Typography>
      </Grid>

      {devices.map((device) => (
        <>
          {user.level && user.level >= ADMIN_USER_LEVEL && (
            <Grid
              item
              xs={isAdmin ? 2 : 0}
              key={`key-device-admin-${device.id}`}
            >
              <Paper
                style={{
                  minHeight: "110px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  p={2}
                  className={`${flexWrap} ${loading ? disabled : null}`}
                  style={{ margin: "auto" }}
                >
                  {sshTunnel.sshPort > 0 && sshTunnel.uuid === device.uuid && (
                    <Chip
                      label={sshTunnel.sshPort}
                      style={{ cursor: "pointer" }}
                      deleteIcon={sshTunnel.sshPort > 0 ? <Close /> : <></>}
                      onDelete={async () => await closeSSHTunnel(device)}
                    />
                  )}
                  {sshTunnel.sshPort <= 0 && (
                    <>
                      <Info
                        fontSize="large"
                        style={{ cursor: "pointer" }}
                        onClick={() => showDetails(device)}
                      />

                      <Terminal
                        fontSize="large"
                        onClick={async () => await openSSHTunnel(device)}
                        style={{ cursor: "pointer" }}
                      />

                      <Upgrade
                        fontSize="large"
                        onClick={() => {
                          const confirmed = window.confirm(
                            `Send upgrade signal to ${device.uuid}?`
                          );
                          if (confirmed) {
                            requestUpgrade(device).then().catch(console.error);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  )}
                </Box>
              </Paper>
            </Grid>
          )}
          <Grid item xs={isAdmin ? 10 : 12} key={`key-device-${device.id}`}>
            <Device
              {...{ device, refreshDevice: get, parentLoading: loading }}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};
