import { Box, Container, Modal } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { globalModalStore } from '../../state-manager/GlobalModalStore'
import useStyles from '../../styles'
import { Footer } from '../global/Footer'
import { GlobalSnackBar } from '../global/GlobalSnackBar'
import { Header } from '../global/Header'
import { SideMenu } from '../global/SideMenu'
import { Spinner } from '../global/Spinner'

export interface DashboardComponentInterface {
  fullWidth?: boolean
}

export const Dashboard: React.FC<DashboardComponentInterface> = ({ children, fullWidth }) => {
  const globalModal = useRecoilValue(globalModalStore)
  const classes = useStyles()

  return (
    <Box style={{ height: '100%' }} margin={0} padding={0}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          <SideMenu />
        </Box>
        <Container maxWidth={fullWidth ? false : 'lg'}>
          <Header />
          <Box p={2} paddingTop={3}>
            {children}
          </Box>
          <Footer />
        </Container>
      </Box>
      <Spinner />
      <GlobalSnackBar />
      <Modal open={globalModal.visible} BackdropProps={{ className: classes.backdropFilter }}>
        <Box className={classes.modal}>{globalModal.children || <div></div>}</Box>
      </Modal>
    </Box>
  )
}

export default Dashboard
