import { Card, CardContent, Typography, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles from '../styles'

export const Retry = () => {
  const { t } = useTranslation('retry')
  const { center } = useStyles()
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5">{t('Dispositivo non raggiungibile')}</Typography>
        <Box marginTop={5} className={center}>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            {t('Riprova')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
