import { atom, selector } from "recoil";

export interface GlobalProviderInterface {
  appReady: boolean;
  showTinySpinner: boolean;
  showSpinner: boolean;
  snackBar: {
    open: boolean;
    message: string;
    timeout?: number;
  };
  sideMenu: boolean;
}

export const defaultGlobalContextStore: GlobalProviderInterface = {
  appReady: false,
  showTinySpinner: false,
  showSpinner: false,
  snackBar: { open: false, message: "" },
  sideMenu: false,
};

export const globalContextStore = atom<GlobalProviderInterface>({
  key: "globalContext",
  default: defaultGlobalContextStore,
});

export const tinySpinnerSelector = selector<
  GlobalProviderInterface["showTinySpinner"]
>({
  key: "showTinySpinner",
  get: ({ get }) => get(globalContextStore).showTinySpinner,
  set: ({ set, get }, showTinySpinner) =>
    set(globalContextStore, {
      ...get(globalContextStore),
      showTinySpinner: !!showTinySpinner,
    }),
});

export const snackBarSelector = selector<GlobalProviderInterface["snackBar"]>({
  key: "snackBar",
  get: ({ get }) => get(globalContextStore).snackBar,
  //@ts-ignore
  set: ({ set, get }, newValue) =>
    //@ts-ignore
    set(globalContextStore, {
      ...get(globalContextStore),
      ...{ snackBar: newValue },
    }),
});

export const sideMenuSelector = selector<GlobalProviderInterface["sideMenu"]>({
  key: "sideMenu",
  get: ({ get }) => get(globalContextStore).sideMenu,
  set: ({ set, get }, sideMenu) =>
    set(globalContextStore, {
      ...get(globalContextStore),
      sideMenu: !!sideMenu,
    }),
});

export default globalContextStore;
