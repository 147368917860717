import { Menu } from '@mui/icons-material'
import { Box, CircularProgress, Grid } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useLargeDesktop } from '../../hooks/isLargeDesktop'
import { sideMenuSelector } from '../../state-manager/GlobalProvider'
import { headerSpinner } from '../../state-manager/HeaderSpinner'
import { Logo } from './Logo'

export const Header = () => {
  const showSideMenu = useSetRecoilState(sideMenuSelector)
  const spinner = useRecoilValue(headerSpinner)
  const isLargeDesktop = useLargeDesktop()

  return (
    <Box paddingLeft={2} paddingRight={2}>
      <Grid container padding={2} style={{ backgroundColor: '#fff', borderRadius: '0px 0px 5px 5px', borderBottom: '2px solid #ccc' }}>
        <Grid item padding={0} xs={1} display="flex" alignItems="center">
          {!isLargeDesktop && <Menu style={{ cursor: 'pointer' }} onClick={() => showSideMenu(true)} />}
        </Grid>
        <Grid item padding={0} xs={10} display="flex" alignItems="center">
          <Logo clickable={false} />
        </Grid>
        <Grid item padding={0} xs={1} display="flex" alignItems="center">
          {spinner && <CircularProgress size="1.5rem" />}
        </Grid>
      </Grid>
    </Box>
  )
}
