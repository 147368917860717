import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../styles'
import { Retry } from './Retry'

export interface ErrorComponenteInterface {
  message?: string
}

export const Error: React.FC<ErrorComponenteInterface> = ({ message }) => {
  const { t } = useTranslation('error')
  const { center } = useStyles()
  return (
    <Box className={center}>
      <Typography variant="h1">{t('Errore')}</Typography>
      <Typography variant="h6">{message || t('Errore sconosciuto')}</Typography>
      <Retry />
    </Box>
  )
}
