import axios from "axios";
import { getToken } from "./../util/getToken";

export const AxiosClient = () => {
  const token = getToken();

  const headers = {
    "Content-Type": "application/json",
    "x-api-key": `${process.env.WEBSERVER_API_KEY}`,
  };

  if (token) Object.assign(headers, { Authorization: `Bearer ${token}` });

  return axios.create({ timeout: 10000, headers });
};
