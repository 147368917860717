import {
  Button,
  Box,
  Grid,
  TextField,
  InputLabel,
  Typography,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GUEST_LEVEL } from "../common/env";
import { useLogin } from "../hooks/useLogin";
import { useUserInfo } from "../hooks/useUserInfo";
import useStyles from "../styles";
import { theme } from "../theme";
import { isValidPassword } from "../util/isValidPassword";
import { isValidUsername } from "../util/isValidUsername";

export const Login: React.FC = () => {
  const { t } = useTranslation("login");
  const [data, setData] = useState({
    username: "",
    password: "",
    validPassword: false,
    validUsername: false,
  });
  const { center, radius, shadow, bgPaper } = useStyles();

  const { user, get, reset } = useUserInfo();
  const { error, logout, login } = useLogin({
    onLogin: () => get(),
    onLogout: () => reset(),
  });

  useEffect(() => {
    if (Number(user.level) <= GUEST_LEVEL) return;
    window.location.href = "/panel/";
  }, [user]);

  return (
    <Box className={`${center} ${radius} ${shadow} ${bgPaper}`} p={3}>
      {user.level === null && (
        <FormControl>
          <Grid container p={2}>
            <Grid item xs={12} p={2}>
              <Typography variant="h3">{t("Login")}</Typography>
            </Grid>
            <Grid item xs={12} p={2}>
              <TextField
                autoFocus={true}
                value={data.username}
                variant="standard"
                required={true}
                helperText={t("Nome utente o email")}
                onChange={(event) =>
                  setData({
                    ...data,
                    username: `${event.target.value}`,
                    validUsername: isValidUsername(event.target.value),
                  })
                }
                error={!data.validUsername}
              />
            </Grid>
            <Grid item xs={12} p={2}>
              <TextField
                value={data.password}
                type="password"
                variant="standard"
                required={true}
                onChange={(event) => {
                  setData({
                    ...data,
                    password: `${event.target.value}`,
                    validPassword: isValidPassword(event.target.value),
                  });
                }}
                onKeyPress={(event) => {
                  event.key === "Enter" && login(data);
                }}
                helperText={t("Inserisci password")}
                error={!data.validPassword}
              />
            </Grid>
            <Grid item xs={12} p={2}>
              <Typography color={theme.palette.warning.dark}>
                {error && t("Login fallito")}
              </Typography>
            </Grid>
            <Grid item xs={12} p={2}>
              <Button variant="contained" onClick={() => login(data)}>
                {t("Accedi")}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      )}
      {user.level !== null && (
        <Button variant="contained" onClick={() => logout()}>
          {t("Logout")}
        </Button>
      )}
    </Box>
  );
};
