import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { globalSpinnerStore } from "../state-manager/GlobalSpinner";

export const Loading: React.FC = () => {
  const showSpinner = useSetRecoilState(globalSpinnerStore);
  useEffect(() => {
    showSpinner(true);
    return () => showSpinner(false);
  }, []);

  return <></>;
};
