export const ROUTE_BASE = "panel";

export const MAIN_API_URL = `${ROUTE_BASE}/api/v1`;

export const MAIN_WEBSITE = "https://www.mascia.eu/";

export const API_PROTOCOL = "https";

export const SNACKBAR_TIMEOUT = 4000;

export const USER_TOKEN_COOKIENAME = "userToken";

export const ADMIN_USER_LEVEL = 9999;

export const CUSTOMER_CARE_LEVEL = 2000;

export const USER_LEVEL = 1000;

export const GUEST_LEVEL = 0;

export const DEVICE_REFRESH_INTERVAL = 30000;
