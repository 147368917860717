import { useRecoilValue } from 'recoil'
import { userInfoStore } from '../state-manager/UserInfoStore'

export interface UserLoggedComponentInterface {
  condition: boolean
}

export const UserLogged: React.FC<UserLoggedComponentInterface> = ({ condition, children }) => {
  const user = useRecoilValue(userInfoStore)

  return <>{condition === !!user.level && children}</>
}
