import { Backdrop, CircularProgress } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { globalSpinnerStore } from '../../state-manager/GlobalSpinner'

export const Spinner: React.FC = () => {
  const showSpinner = useRecoilValue(globalSpinnerStore)

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={showSpinner}>
      {showSpinner && <CircularProgress />}
    </Backdrop>
  )
}
