import { apiDeviceAdapter } from "../adapter/apiDeviceAdapter";
import { MAIN_API_URL } from "../common/env";
import { ApiDeviceAdapter } from "../interface/ApiDeviceAdapter";
import { ApiDeviceResponse } from "../interface/ApiDeviceResponse";
import { AxiosClient } from "./axiosClient";

export const getCurrentDevices = (): Promise<ApiDeviceAdapter[]> => {
  const client = AxiosClient();
  return client
    .get<ApiDeviceResponse[]>(`/${MAIN_API_URL}/device/devices/current`)
    .then(({ data }) => data.map(apiDeviceAdapter));
};
