import { apiUserDataAdapter } from "../adapter/apiUserDataAdapter";
import { MAIN_API_URL } from "../common/env";
import { ApiUserDataAdapter } from "../interface/ApiUserDataAdapter";
import { ApiUserDataResponse } from "./../interface/ApiUserDataResponse";
import { AxiosClient } from "./axiosClient";

export const getUserData = (): Promise<ApiUserDataAdapter> => {
  const client = AxiosClient();
  return client
    .get<ApiUserDataResponse>(`/${MAIN_API_URL}/users/current`)
    .then(({ data }) => apiUserDataAdapter(data));
};
