import { CssBaseline, ThemeProvider } from '@mui/material'
import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import App from './App'
import { theme } from './theme'

window.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('root')

  ReactDOM.render(
    <RecoilRoot>
      <Suspense fallback={<div>Loading...</div>}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Suspense>
    </RecoilRoot>,
    rootElement
  )
})
