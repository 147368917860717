import {
  Box,
  Button,
  Divider,
  MenuItem,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ROUTE_BASE } from "../../common/env";
import { useLargeDesktop } from "../../hooks/isLargeDesktop";
import { useIsMobile } from "../../hooks/isMobile";
import { useLogin } from "../../hooks/useLogin";
import { useUserInfo } from "../../hooks/useUserInfo";
import { sideMenuSelector } from "../../state-manager/GlobalProvider";
import { theme } from "../../theme";
import { UserLogged } from "../UserLogged";
import { useStyles } from "./../../styles";

export const SideMenu = () => {
  const classes = useStyles();
  const [sideMenu, setSideMenu] = useRecoilState(sideMenuSelector);
  const { t } = useTranslation("side-menu");
  const isMobile = useIsMobile();
  const isLargeDesktop = useLargeDesktop();
  const { user, reset } = useUserInfo();
  const { logout } = useLogin({ onLogout: () => reset() });

  return (
    <>
      {isLargeDesktop && <Box style={{ width: "300px" }}></Box>}
      <SwipeableDrawer
        anchor="left"
        variant={isLargeDesktop ? "permanent" : "temporary"}
        open={sideMenu}
        onOpen={() => setSideMenu(true)}
        onClose={() => setSideMenu(false)}
      >
        {user?.data && (
          <Box
            p={4}
            style={{
              height: "5rem",
              color: theme.palette.text.primary,
              background: theme.palette.primary.light,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
              <Typography variant="h6">
                {user?.data?.name && t("Ciao")},{" "}
              </Typography>
              <Typography variant="h5" style={{ paddingLeft: "0.3rem" }}>
                {user?.data?.name && capitalize(user?.data?.name)}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          p={0}
          style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "70vw" : "300px",
          }}
        >
          <UserLogged condition={false}>
            <Link
              className={classes.link}
              to={`/${ROUTE_BASE}/login`}
              onClick={() => setSideMenu(false)}
            >
              <MenuItem>{t("Accedi")}</MenuItem>
            </Link>
            <Divider orientation="horizontal" />
          </UserLogged>
          <UserLogged condition={true}>
            <Link className={classes.link} to={`/${ROUTE_BASE}`}>
              <MenuItem onClick={() => logout()}>{t("Disconnetti")}</MenuItem>
            </Link>
            <Divider orientation="horizontal" />
          </UserLogged>

          {!isLargeDesktop && (
            <Button onClick={() => setSideMenu(false)}>{t("Chiudi")}</Button>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};
