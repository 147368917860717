import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getCurrentDevices } from "../service/getCurrentDevices";
import { devicesStore } from "../state-manager/DevicesStore";
import { getToken } from "../util/getToken";
import { apiDeviceAdapter } from "./../adapter/apiDeviceAdapter";

export const useCurrentDevices = () => {
  const [time, setTime] = useState<number>();
  const [error, setError] = useState<Error>();
  const [devices, setDevices] = useRecoilState(devicesStore);
  const [loading, setLoading] = useState(false);

  const get = () => setTime(Date.now());
  const reset = () => setDevices([]);

  useEffect(() => {
    const token = getToken();
    if (token) get();
  }, []);

  useEffect(() => {
    if (!time) return;
    setLoading(true);
    getCurrentDevices()
      .then((data) => {
        setDevices(data.map(apiDeviceAdapter));
      })
      .catch((err) => {
        reset();
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [time]);

  return { error, loading, devices, get, reset };
};
