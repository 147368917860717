import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userRoleToLevel } from "../service/userRoleToLevel";
import { userInfoStore } from "../state-manager/UserInfoStore";
import { getToken } from "../util/getToken";
import { getUserData } from "./../service/getUserData";
import { userInfoStoreDefault } from "./../state-manager/UserInfoStore";

export const useUserInfo = () => {
  const [time, setTime] = useState<number>();
  const [error, setError] = useState<Error>();
  const [user, setUser] = useRecoilState(userInfoStore);
  const [loading, setLoading] = useState(false);

  const get = () => setTime(Date.now());
  const reset = () => setUser(userInfoStoreDefault);

  useEffect(() => {
    const token = getToken();
    if (token && user.level === null) get();
  }, []);

  useEffect(() => {
    if (!time) return;
    setLoading(true);
    getUserData()
      .then((data) => {
        setUser({ data, level: userRoleToLevel(data.level) });
      })
      .catch((err) => {
        reset();
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [time]);

  return { error, loading, user, get, setUser, reset };
};
