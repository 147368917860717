import { ApiLoginResponse } from "./../interface/ApiLoginResponse";
import { apiLoginAdapter } from "../adapter/apiLoginAdapter";
import { MAIN_API_URL } from "../common/env";
import { AxiosClient } from "./axiosClient";

export const loginWithApi = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const client = AxiosClient();
  return client
    .post<ApiLoginResponse>(`/${MAIN_API_URL}/users/login`, {
      username,
      password,
    })
    .then(({ data }) => apiLoginAdapter(data));
};
